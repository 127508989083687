<template>
  <div class="product-single product-function">
    <div class="main-wrap">
      <div class="tit-box pr">
        <h2>产品功能</h2>
      </div>
      <div class="con-box">
        <div class="tab-cont">
          <span>
            <ul
              :class="liLen <= 5 ? 'ul-listCon' : ''"
              class="ul-list clearfloat"
            >
              <li
                v-for="(productFun, index) in functionPro"
                :key="index"
                style="visibility: visible; "
              >
                <a href="javascript:;">
                  <div class="marking_img">
                    <img :src="productFun.src" />
                  </div>
                  <dl class="dl_marking">
                    <dd>{{ productFun.tit }}</dd>
                    <dt>
                      {{ productFun.con }}
                      <p>{{ productFun.con1 }}</p>
                    </dt>
                  </dl>
                </a>
              </li>
              <div class="clear"></div>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ProductFunction",
  props: {
    functionPro: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      liLen: 0
    };
  },
  computed: {},
  mounted() {
    this.liLen = this.functionPro.length;
  },
  methods: {}
};
</script>
